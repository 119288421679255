import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { TechPartnerService } from '../../services/techpartner.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { TechPartnerFullCard } from '../../models/TechPartnerFullCard.model';
import { LocalStorageService } from '../../services/localStorage.service';
import { FilterRequestBody } from '../../models/FilterRequestBody.model';
import { FiltersService } from '../../services/filter.service';


@Component({
  selector: 'app-tech-partner-list-all',
  templateUrl: './tech-partner-list-all.component.html',
  styleUrls: ['./tech-partner-list-all.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('200ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class TechPartnerListAllComponent implements OnInit, OnDestroy {

  constructor(
    private localStorageService: LocalStorageService, 
    private cd: ChangeDetectorRef, 
    private techPartnerService: TechPartnerService, 
    private filterService: FiltersService) { }

  private unsubscribe$: Subject<void> = new Subject<void>();
  selectedPartners: TechPartnerFullCard[] = [];
  techPartners: Map<number, TechPartnerFullCard> = new Map();
  isLoadingMore: boolean = false;
  currentPage = signal(0);
  size: number = 10;
  seed: number = Math.floor(100 + Math.random() * 900);

  isLoading: boolean = true;

  ngOnInit(): void {
    this.filterService.setFilters(null);
    this.getSelectedPartners();
    this.loadTechPartnerList();
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handlePartnerSelection(partner: TechPartnerFullCard, selected: boolean) {
    if (selected && this.selectedPartners.length < 4) {
      this.selectedPartners.push(partner);
    } else if (!selected) {
      this.selectedPartners = this.selectedPartners.filter(p => p.card.id !== partner.card.id);
    }
    this.localStorageService.saveSelectedPartners(this.selectedPartners);
  }

  isPartnerSelected(partner: TechPartnerFullCard): boolean {
    return this.selectedPartners.some(p => p.card.id === partner.card.id);
  }

  isCheckboxDisabled(partner: TechPartnerFullCard): boolean {
    return !this.isPartnerSelected(partner) && this.selectedPartners.length >= 4;
  }

  clearSelection() {
    this.selectedPartners = [];
    this.localStorageService.clearSelectedPartners();
    this.localStorageService.clearSelectedPartnersToContact();
  }

  loadTechPartnerList() {
    const requestBody: FilterRequestBody = {};
    this.isLoading = true;
    this.techPartnerService.loadTechPartnerFullList(requestBody, this.currentPage(), this.size, this.seed)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (response) => {
          response.data.forEach(item => {
            if(!this.techPartners.has(item.card.id)){
              this.techPartners.set(item.card.id, item);
            }
          })
        },
        error: (error) => {
          console.error('Error loading tech partners:', error);
          this.isLoading = false;
        }
      });
  }

  getSelectedPartners() {
    this.selectedPartners = this.localStorageService.getSelectedPartners();
  }

  get partnersArray(): TechPartnerFullCard[] {
    return Array.from(this.techPartners.values());
  }

  onScrollDown() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.currentPage.set(this.currentPage() + 1);

    this.techPartnerService
      .loadTechPartnerFullList(
        {},
        this.currentPage(),
        this.size,
        this.seed
      )
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (response) => {
          response.data.forEach(item => {
            if (!this.techPartners.has(item.card.id)) {
              this.techPartners.set(item.card.id, item);
            }
          })
        },
        error: (error) => {
          console.error('Error loading more tech partners:', error);
          this.isLoading = false;
        },
      });
  }
}
