import { PaginatedList } from './../models/PaginatedList.model';
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { signal } from '@angular/core';
import { TechPartnerFullCard } from '../models/TechPartnerFullCard.model';
import { environment } from '../../environments/environment';
import { GenericListWrapper } from '../models/GenericListWrapper.model';
import { FilterRequestBody } from '../models/FilterRequestBody.model';
import { TechPartnerFullProfile } from '../models/TechPartnerFullProfile';

@Injectable({
  providedIn: 'root',
})
export class TechPartnerService {
  private http = inject(HttpClient);
  private apiUrl = environment.routes.techPartnerProfile;
  private apiUrlGetAll = environment.routes.techPartner;

  // State management using signal
  techPartnerList = signal<TechPartnerFullCard[]>([]);
  techPartnerProfile?: TechPartnerFullProfile;

  constructor() {}

  loadTechPartnerFullList(
    body: FilterRequestBody,
    page: number = 0,
    size: number = 10,
    seed: number = 123
  ): Observable<PaginatedList<TechPartnerFullCard>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('seed', seed.toString());

    return this.http
      .post<PaginatedList<TechPartnerFullCard>>(this.apiUrlGetAll, body, { params })
      .pipe(
        tap((techPartnerList) =>
          this.techPartnerList.set(techPartnerList.data)
        ),
        catchError(
          this.handleError<PaginatedList<TechPartnerFullCard>>(
            'loadTechPartnerFullList'
          )
        )
      );
  }

  getFilters() {
    return this.http.get<any>(environment.routes.techPartnerFilters);
  }

  loadTechPartnersFilteredList(
    body: FilterRequestBody,
    page?: number,
    size?: number,
    query?: string,
    seed: number = 123
  ): Observable<PaginatedList<TechPartnerFullCard>> {
    let params = new HttpParams();

    if (page) params = params.append('page', page);
    if (size) params = params.append('size', size);
    if (query) params = params.append('query', query);
    if (seed) params = params.append('seed', seed);

    return this.http
      .post<PaginatedList<TechPartnerFullCard>>(
        environment.routes.filteredTechParners,
        body,
        { params: params }
      )
      .pipe(
        tap((techPartnerList) =>
          this.techPartnerList.set(techPartnerList.data)
        ),
        catchError(
          this.handleError<PaginatedList<TechPartnerFullCard>>(
            'loadTechPartnerFullList'
          )
        )
      );
  }

  loadTechPartnerFullProfile(
    techPartnerProfileId: number
  ): Observable<TechPartnerFullProfile> {
    const apiUrlId = this.apiUrl + '/' + techPartnerProfileId;

    return this.http.get<TechPartnerFullProfile>(apiUrlId).pipe(
      tap(
        (techPartnerProfile) => (this.techPartnerProfile = techPartnerProfile)
      ),
      catchError(
        this.handleError<TechPartnerFullProfile>('loadTechPartnerFullProfile')
      )
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
