<h3>Todos os Parceiros</h3>

<section class="wrapper" infinite-scroll [infiniteScrollDistance]="5" (scrolled)="onScrollDown()">
  <div class="grid-container">
    <div class="grid-item" *ngFor="let partner of partnersArray">
      <app-app-partner-card [partner]="partner" (selectPartner)="handlePartnerSelection(partner, $event)"
        [isSelected]="isPartnerSelected(partner)" [isCheckboxDisabled]="isCheckboxDisabled(partner)">
      </app-app-partner-card>
    </div>
  </div>
</section>

<ng-container #loading>
  <app-app-loader-spinner [isLoading]="isLoading" ></app-app-loader-spinner>
</ng-container>

<app-app-partners-selection-footer [partners]="selectedPartners" (clearSelection)="clearSelection()" [@fadeInOut]
  *ngIf="selectedPartners.length"></app-app-partners-selection-footer>